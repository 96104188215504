<template>
  <div class="full-width full-height">
    <div class="flex items-center justify-between m-b-sm">
      <div class="flex items-center flex-one">
        <span class="flex-s m-r-xs">省份：</span>
        <a-select
          placeholder="请选择省份"
          class="search-mx-wd-1 m-r-sm"
          v-model="regionId"
          @change="changeProvince"
        >
          <a-select-option
            v-for="item in provinceList"
            :key="item.regionCode"
            :value="item.regionCode"
          >
            {{ item.alias }}
          </a-select-option>
        </a-select>
        <span class="flex-s m-r-xs">管辖单位：</span>
        <a-cascader
          :options="organizationList"
          :display-render="displayRender"
          v-model="organizationId"
          class="search-mx-wd-1 m-r-sm"
          expand-trigger="hover"
          placeholder="管辖单位"
          change-on-select
          @change="selectOrganization"
        />
        <span class="flex-s m-r-xs">摄像机名称：</span>
        <a-input
          v-model="cameraName"
          class="search-mx-wd-1  m-r-sm"
          placeholder="请输入摄像机名称"
        />
        <span class="flex-s m-r-xs">截图时间：</span>
        <a-range-picker
          v-model="dateRange"
          format="YYYY-MM-DD HH:mm:ss"
          class="search-date-picker m-r-sm"
          showTime
          @change="changeDataRange"
        />
        <a-button
          type="primary"
          class="m-r-sm btn-search"
          @click="searchHandle"
        >
          搜索
        </a-button>
        <a-button class="btn-reset" @click="resetHandle">
          重置
        </a-button>
      </div>
      <div class="flex">
        <span class="span-line m-l-md m-r-md"></span>
        <a-button type="button" class="btn-export" @click="handleIntelli"
          >智能分析</a-button
        >
      </div>
    </div>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => index"
      :data-source="cameraInfoList"
      :pagination="paginationOpt"
      :components="resibleTableHeader"
      bordered
      :scroll="scroll"
    >
      <template slot="customOpe" slot-scope="text, record">
        <a-tooltip placement="top">
          <template slot="title">
            <span>查看分析结果</span>
          </template>
          <button class="button-default">
            <a-icon
              type="profile"
              class="pointer"
              :class="{ 'not-cursor icon-disabled': getViewDisabled(record) }"
              @click="viewResult(record)"
            />
          </button>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>删除</span>
          </template>

          <a-icon
            type="rest"
            class="m-l-sm pointer"
            @click="delHandle(record)"
          />
        </a-tooltip>
      </template>
    </a-table>

    <image-result
      :visible="resultVisble"
      @close="closeHandle"
      :currentTaskId="currentTaskId"
    />
    <intelli-an
      :visible="intelliVisible"
      @close="closeIntell"
      @sure="sureHandle"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ImageResult from './ImageResult';
import IntelliAn from './IntelliAn';
import moment from 'moment';
import { CODE_OK } from '@/request/config_code';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'ImageAn',
  mixins:[ResizableTable],
  data() {
    return {
      regionId: undefined,
      organizationId: [],
      cameraName: '',
      dateRange: null,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: total => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getImageAn();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getImageAn();
        },
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'imageIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '区域',
          dataIndex: 'regionName',
          key: 'regionName',
          width: 90,
          ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          key: 'organizationName',
          width: 100,
          ellipsis: true,
        },
        {
          title: '所属路线',
          dataIndex: 'roadName',
          key: 'roadName',
          width: 100,
          ellipsis: true,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          width:200,
          ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'kmHmPile',
          key: 'kmHmPile',
          width: 100,
        },
        {
          title: '分析方式',
          dataIndex: 'analysisTypeStr',
          key: 'analysisTypeStr',
          width: 100,
          ellipsis: true,
        },
        {
          title: '开始时间',
          dataIndex: 'snapshotStartTime',
          key: 'snapshotStartTime',
          width: 100,
          ellipsis: true,
        },
        {
          title: '结束时间',
          dataIndex: 'snapshotEndTime',
          key: 'snapshotEndTime',
          width: 100,
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          width: 100,
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'analysisStatusStr',
          key: 'analysisStatusStr',
          width: 100,
        },

        {
          title: '操作',
          dataIndex: 'ope',
          key: 'ope',
          ellipsis: true,
          width:100,
          className:'column-center',
          scopedSlots: { customRender: 'customOpe' },
        },
      ],
      cameraInfoList: [],
      resultVisble: false,
      intelliVisible: false,
      currentTaskId: '',
      scroll: { y: window.innerHeight - 350 ,x:'100%'},
    };
  },
  computed: {
    ...mapState({
      provinceList: state => state.collect.provinceList,
      organizationList: state => state.collect.organizationList,
    }),
  },
  watch: {},
  components: {
    ImageResult,
    IntelliAn,
  },
  mounted() {
    this.getProvince({ regionLevel: 1 });
    this.getOrganationList();
    this._getImageAn();
  },
  methods: {
    ...mapActions(['getProvince', 'getOrganationList']),
    getDelDisabled(record) {
      return record.analysisStatus === 0 || record.analysisStatus === -1;
    },
    getViewDisabled(record) {
      return record.analysisStatus !== 1;
    },
    changeProvince(val) {
      this.regionId = val;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value) {
      this.organizationId = value;
    },
    changeDataRange(date) {
      this.dateRange = date;
    },
    _getImageAn() {
      let params = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        regionCode: this.regionId,
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
        cameraName: this.cameraName,
        snapshotStartTime: this.dateRange
          ? moment(this.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        snapshotEndTime: this.dateRange
          ? moment(this.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
      this.$api.imageAn.getImageList(params).then(res => {
        if (res.code === CODE_OK) {
          this.cameraInfoList = res.data;
          this.paginationOpt.total = res.total;
        } else {
          this.$message.error('获取图像分析列表失败！');
        }
      });
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getImageAn();
    },
    resetHandle() {
      this.regionId = undefined;
      this.organizationId = [];
      this.cameraName = '';
      this.dateRange = null;
      this.searchHandle();
    },
    delHandle(record) {
      if (!this.hasPermission(109211111311)) {
        this.permissionWarning();
        return;
      }

      this.$confirm({
        title: '提示',
        content: '确定删除该条图片分析任务吗?',
        onOk: () => {
          this.$api.imageAn.deleteImageAn(record.taskId).then(res => {
            if (res.code === CODE_OK) {
              this.$message.success('删除该条图片分析任务成功！');
              this._getImageAn();
            } else {
              this.$message.error('删除操作请求出错！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    viewResult(record) {
      // console.log(record);
      if (this.getViewDisabled(record)) {
        return;
      }
      this.currentTaskId = record.taskId;
      this.resultVisble = true;
    },
    closeHandle() {
      this.resultVisble = false;
      this.currentTaskId = '';
    },
    handleIntelli() {
      if (!this.hasPermission(109211111310)) {
        this.permissionWarning();
        return;
      }

      this.intelliVisible = true;
    },
    closeIntell() {
      this.intelliVisible = false;
    },
    sureHandle() {
      this.closeIntell();
      this.paginationOpt.current = 1;
      this._getImageAn();
    },
  },
};
</script>

<style lang="less" scoped>
.wd120 {
  width: 14%;
  max-width: 120px;
  min-width: 120px;
}
.wd140 {
  width: 20%;
  max-width: 140px;
  min-width: 140px;
}
</style>
