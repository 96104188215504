<template>
  <transition name="animate">
    <div class="fix full-width full-height l0 t0 z-999" v-if="visible">
      <div class="dialog-mask full-width full-height absolute l0 t0 z-1"></div>
      <div
        class="flex items-center justify-center full-width full-height relative z-2 text-lightblue f16"
      >
        <img
          :src="url"
          class="max-width max-height img-dom"
          @load="loadHandle"
          ref="imgDom"
        />
        <div class="flex m-l-sm pointer" :style="styleObj">
          <a-icon type="close" @click="closeHandle" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Album',
  props: {
    url: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgHt: 0,
    };
  },
  computed: {
    styleObj() {
      return `height:${this.imgHt}px`;
    },
  },
  watch: {},
  methods: {
    loadHandle() {
      this.imgHt = this.$refs.imgDom.offsetHeight;
    },
    closeHandle() {
      this.$emit('close', false);
    },
  },
};
</script>

<style scoped>
.animate-enter-active,
.animate-leave-active {
  transition: all 0.5s;
}
.animate-enter,
.animate-leave-to {
  opacity: 0;
}
.animate-enter .img-dom,
.animate-leave-to .img-dom {
  transform: scale(0);
}
.animate-enter-active .img-dom,
.animate-leave-active .img-dom {
  transition: all 0.5s;
}
</style>
