<template>
  <a-modal
    title="分析详情"
    :visible="visible"
    width="70%"
    :centered="true"
    :footer="null"
    :bodyStyle="bodyFormatStyle"
    @cancel="handleCancel"
  >
    <a-table
      :columns="columns"
      :rowKey="(record, index) => index"
      :data-source="cameraInfoList"
      :pagination="paginationOpt"
      :loading="loading"
      bordered
    >
    </a-table>
    <div class="flex justify-center m-b-sm">
      <a-button type="primary" @click="sureHandle">确定</a-button>
    </div>
  </a-modal>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'ImageResult',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentTaskId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bodyFormatStyle: {
        minHeight: '400px',
        height: '70%',
        maxHeight: `${window.innerHeight - 100}px`,
      },
      loading: false,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        // showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.getDetail();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.getDetail();
        },
      },
      cameraInfoList: [],
      columns: [
        {
          title: '排序',
          customRender: (text, record, index) => index + 1,
          width: 80,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          width: '14%',
          ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'kmHmPile',
          key: 'kmHmPile',
          width: '12%',
          ellipsis: true,
        },
        {
          title: '截图时间',
          dataIndex: 'snapshotTime',
          key: 'snapshotTime',
          width: '20%',
          ellipsis: true,
        },
        {
          title: '车辆密度',
          dataIndex: 'trafficStatusResult',
          key: 'trafficStatusResult',
          ellipsis: true,
        },
        {
          title: '公路事件',
          dataIndex: 'roadEventResult',
          key: 'roadEventResult',
          width: '12%',
          ellipsis: true,
        },
        {
          title: '视频质量',
          dataIndex: 'videoQualityResult',
          key: 'videoQualityResult',
          width: '12%',
          ellipsis: true,
        },
      ],
    };
  },
  computed: {},
  watch: {
    currentTaskId(nv, ov) {
      if (nv !== ov) {
        this.getDetail();
      }
    },
  },
  methods: {
    getDetail() {
      let params = {
        taskId: this.currentTaskId,
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
      };
      this.loading = true;
      this.$api.imageAn.getImageDetail(params).then((res) => {
        if (res.code === CODE_OK) {
          this.cameraInfoList = res.data;
          this.paginationOpt.total = res.total;
          this.loading = false;
        } else {
          this.$message.error('获取详情出错！');
        }
      });
    },
    resetData() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 5;
    },
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
    sureHandle() {
      this.handleCancel();
    },
  },
};
</script>

<style scoped></style>
