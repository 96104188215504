<template>
  <div
    class="full-width ht"
    :style="{ height: $g.isDepart ? 'calc(100% - 60px)' : 'calc(100% - 30px)' }"
  >
    <div class="on-con relative p-md full-height">
      <span class="cor absolute t-lf"></span>
      <span class="cor absolute t-rf"></span>
      <span class="cor absolute b-lf"></span>
      <span class="cor absolute b-rf"></span>
      <div class="con-main flex items-center full-height">
        <div class="f-l full-height m-r-md">
          <cameral-route :mode="1" @postCamera="postCameraHandle" />
        </div>
        <div class="f-r flex-one full-height">
          <div class="fl-menu flex items-center m-b-sm" v-show="$g.isDepart">
            <span
              :class="[
                {
                  current: activeMenu === 0,
                  'not-cursor': loading,
                },
                'pointer',
              ]"
              @click="tabClick(0)"
              >我的截图</span
            >
            <span
              v-if="hasPermission(109211111110)"
              :class="[
                {
                  current: activeMenu === 1,
                  'not-cursor': loading,
                },
                'pointer',
              ]"
              @click="tabClick(1)"
              >省级管理</span
            >
          </div>
          <div class="flex items-center m-t-sm m-b-sm">
            <div class="flex items-center flex-one">
              <div
                class="m-r-sm text-lightblue flex  items-center search-mx-wd-2"
                v-show="activeMenu === 0"
                v-if="$g.isDepart"
              >
                <span class="flex-s m-r-xs">截图方式：</span>
                <a-select
                  v-model="shotId"
                  class="flex-one"
                  @change="selectShot"
                >
                  <a-select-option
                    v-for="item in shotList"
                    :key="item.id"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>

              <div
                class="m-r-sm text-lightblue flex items-center search-date-picker-tit"
              >
                <span class="flex-s m-r-xs">截图时间：</span>
                <a-range-picker
                  v-model="dataRange"
                  :placeholder="['开始时间', '结束时间']"
                  class="flex-one"
                  @change="changeDataRange"
                  format="YYYY-MM-DD HH:mm:ss"
                  :disabledDate="disabledDate"
                  show-time
                ></a-range-picker>
              </div>
              <a-button
                type="primary"
                class="m-r-sm btn-search"
                @click="searchHandle"
              >
                搜索
              </a-button>
              <a-button class="btn-reset" @click="resetHandle">
                重置
              </a-button>
              <!-- <span class="span-line m-l-md m-r-md"></span>
              <div class="m-r-sm">
                <a-dropdown>
                  <a-button class="btn-export">
                    批量处理
                  </a-button>
                  <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="1">删除</a-menu-item>
                    <a-menu-item key="2">下载</a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div> -->
              <div class="f20 mode-style flex  m-l-sm">
                <a-icon
                  title="图片展示"
                  type="fund"
                  class="m-r-xs pointer"
                  :class="{ current: currentMode === 'pic' }"
                  @click="setCurrentModeHandle('pic')"
                />
                <a-icon
                  title="列表展示"
                  type="unordered-list"
                  class="pointer"
                  :class="{ current: currentMode === 'list' }"
                  @click="setCurrentModeHandle('list')"
                />
              </div>
            </div>
          </div>
          <vue-scroll
            :ops="$root.scrollOpsY"
            :style="{
              height: $g.isDepart ? 'calc(100% - 116px)' : 'calc(100% - 86px)',
            }"
          >
            <div v-if="currentMode === 'pic'">
              <div
                v-show="screenShotDataInfo.length <= 0"
                class="full-width full-height flex items-center justify-center"
              >
                <a-empty v-if="!picLoading" />
                <a-spin v-else></a-spin>
              </div>
              <div
                class="list-wrap flex flex-wrap"
                v-if="screenShotDataInfo.length"
              >
                <div
                  v-for="(item, index) in screenShotDataInfo"
                  class="list-item-wrap"
                  :key="item.id"
                >
                  <div class="list-item relative">
                    <image-holder v-if="!item.snapshotUrl" />
                    <template v-else>
                      <a-checkbox
                        v-model="item.check"
                        @change="onChange($event, index)"
                        class="absolute checkbox  t6 l6"
                      ></a-checkbox>
                      <i
                        title="下载"
                        class="text-lightblue pointer f16 absolute r4 b10 download"
                        @click="downloadImage2(item)"
                      ></i
                      ><!--downloadImage(item.snapshotUrl)-->
                      <img
                        :src="item.snapshotUrl"
                        class="full-width pointer"
                        ref="imgDom"
                        :style="styleHt"
                        @load="loadHandle(index)"
                        @click="viewDetail(index)"
                      />
                    </template>
                  </div>
                  <div class="p-w-sm flex">
                    <a-icon
                      type="like"
                      v-show="$g.isDepart && item.type == 2"
                      class="m-r-xs"
                    />
                    <span
                      class="text-white m-b-sm time f12 flex-one ellipsis"
                      :title="item.snapshotName"
                      >{{ item.snapshotName }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="currentMode === 'list'">
              <list-table
                :fromTab="
                  {
                    0: '我的截图',
                    1: '省级管理',
                  }[activeMenu]
                "
                :data="screenShotDataInfo"
                :selectedRowKeysProp="selectedRowKeysData"
                :downloadImageParent="downloadImage2"
                :viewDetailParent="viewDetail"
                :loading="picLoading"
                @onTable="onTableHandle"
                @sureSearch="searchHandle"
              />
            </div>
          </vue-scroll>
          <div class="flex items-center justify-between m-t-sm">
            <div class="flex items-center">
              <a-checkbox
                v-model="checkAll"
                class="m-r-sm"
                @change="onCheckAllChange"
                :disabled="screenShotDataInfo.length <= 0"
              >
                全选
              </a-checkbox>
              <span class="span-line m-r-sm"></span>
              <span
                class="text-lightblue pointer m-r-sm"
                v-show="activeMenu == 0"
                @click="handleMenuClick('1')"
                >删除</span
              >
              <span class="span-line m-r-sm" v-show="activeMenu == 0"></span>
              <span
                class="text-lightblue pointer m-r-sm"
                @click="handleMenuClick('2')"
                >下载</span
              >
              <span class="span-line m-r-sm" v-show="activeMenu === 1"></span>
              <span
                class="text-lightblue pointer m-r-sm"
                v-show="activeMenu === 1"
                @click="handleImageAnalysis"
                >图片分析</span
              >
            </div>
            <div class="flex items-center">
              <span class="text-lightblue m-r-xs">共有{{ screenTotal }}条</span>
              <a-pagination
                :current="pagination.current"
                :total="pagination.total"
                :pageSize="24"
                show-quick-jumper
                @change="changeCurrent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <snap-shot-detail
      :visible="showAlbum"
      :snapList="screenShotDataInfo"
      :curIndex="curIndex"
      :active="activeMenu"
      :downloadImageParent="downloadImage2"
      @close="showAlbum = false"
    />
    <!-- <album :url="imgUrl" :visible="showAlbum" @close="showAlbum = false" /> -->
    <image-analysis-dialog
      :visible="imageAnalysisVisible"
      :manualCamera="manualCameraList"
      @closeImageAn="closeImageAnHandle"
      @sureImageAn="sureImageAnHandle"
    />
  </div>
</template>

<script>
import CameralRoute from '@/components/CameralRoute';
// import Album from './Album';
import ImageHolder from '@/components/ImageHolder';
import SnapShotDetail from './SnapShotDetail';
import ImageAnalysisDialog from './ImageAnalysisDialog';
import ListTable from './ListTable';
import moment from 'moment';
import { handleZipExportFile } from '@/tools';
import { mapState, mapMutations, mapActions } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import { handleVideo /* getFileType */ } from '@/tools';
export default {
  name: 'Screen',
  data() {
    return {
      activeMenu: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      imgHt: 0,
      imgUrl: '',
      showAlbum: false,
      curIndex: 0,
      cameraName: '',
      shotList: [
        {
          id: 0,
          value: '',
          name: '全部',
          filter: false,
        },
        {
          id: 2,
          value: 2,
          name: '手动截图',
          filter: false,
        },
        {
          id: 1,
          value: 1,
          name: '自动截图',
          filter: true,
        },
      ],
      shotId: '',
      dataRange: null,
      loading: false,
      checkAll: false,
      imageAnalysisVisible: false,
      manualCameraList: [],
      disabledDate: (currentDate) => {
        return currentDate < moment().subtract(7, 'day');
      },
      selectedRowKeysData: [],
    };
  },
  components: {
    CameralRoute,
    // Album,
    ImageHolder,
    SnapShotDetail,
    ListTable,
    ImageAnalysisDialog,
  },
  computed: {
    ...mapState({
      screenShotDataInfo: (state) => state.resource.screenShotDataInfo,
      screenShotSearchParams: (state) => state.resource.screenShotSearchParams,
      screenShotProvinceParams: (state) =>
        state.resource.screenShotProvinceParams,
      screenTotal: (state) => state.resource.screenTotal,
      selectCameraList: (state) => state.resource.selectScreenCameraList,
      picLoading: (state) => state.resource.picLoading,
      currentMode: (state) => state.resource.currentMode,
      isLoading: (state) => state.resource.isLoading,
    }),
    //映射图片高度到样式中
    styleHt() {
      return `height:${this.imgHt}px`;
    },
  },
  watch: {
    /*  screenShotSearchParams: {
      handler() {
        this.pagination.current = 1;
      },
      deep: true,
    }, */
  },
  mounted() {
    if (!this.$g.isDepart) {
      this.shotList = this.shotList.filter((item) => item.filter);
      this.shotId = 1;
    }
    this.initDateRange();
    this.initTabOne();
  },
  methods: {
    ...mapMutations([
      'setScreenShotSearchParams',
      'setScreenShotProvinceParams',
      'setScreenShotDataInfo',
      'setScreenSelectCameraList',
      'setCurrentMode',
      'setIsProvince',
      'setScreenTotal',
    ]),
    ...mapActions([
      'getScreenShotInfo',
      'deleteScreenShout',
      'getProvinceScreenShotInfo',
    ]),
    downloadImage2(item) {
      if (this.activeMenu === 0) {
        // 我
        if (!this.hasPermission(109211111010)) {
          this.permissionWarning();
          return;
        }
      } else if (this.activeMenu === 1) {
        // 部
        if (!this.hasPermission(109211111111)) {
          this.permissionWarning();
          return;
        }
      }

      let data = {
        cameraId: item.cameraId,
        cameraNum: item.cameraNum,
        fileType: item.fileType,
        ossPath: item.snapshotUrl,
        videoTapeName: item.snapshotName,
      };
      let objParams = {
        data,
        instructionsDel: {
          module: '截图和录像',
          page: '截图管理',
          feature: '下载截图',
          description: `下载截图: ${item.cameraName || '暂无名称'}`,
        },
      };
      this.$api.video.downloadVideo(objParams).then((res) => {
        handleVideo(res, `图片.${item.fileType||'jpg'}`);
      });
    },
    postCameraHandle(camera) {
      if (this.activeMenu === 1) {
        console.log('haha', camera);
        let data = {
          ...this.screenShotProvinceParams,
          ...{
            cameraNum: camera.type === 'camera' ? camera.cameraNum : '',
          },
        };
        this.setScreenShotProvinceParams(data);
        this._getScreenProvinceShotList();
      } else {
        let data = {
          ...this.screenShotSearchParams,
        };
        // console.log('gaga', camera);
        if (camera.type === 'camera') {
          data = camera.check
            ? { ...data, ...{ cameraId: camera.cameraId } }
            : { ...data, ...{ cameraId: '' } };
        } /* else {
          data = { ...data, ...{ cameraId: '' } };
        } */
        if (camera.needType === 'regions') {
          data = camera.check
            ? {
                ...data,
                ...{
                  regionCode: camera.regionCodes,
                },
              }
            : { ...data, ...{ regionCode: '' } };
        } /* else {
          data = { ...data, ...{ regionCode: '' } };
        } */
        if (camera.needType === 'region') {
          data = camera.check
            ? {
                ...data,
                ...{
                  regionCode: camera.regionCode,
                },
              }
            : { ...data, ...{ regionCode: '' } };
        } /* else {
          data = { ...data, ...{ regionCode: '' } };
        } */
        if (camera.needType === 'organizationArea') {
          data = camera.check
            ? {
                ...data,
                ...{
                  organizationId: camera.organizationId,
                  highwayType: camera.highwayType,
                },
              }
            : { ...data, ...{ organizationId: '', highwayType: '' } };
        } /* else {
          data = { ...data, ...{ organizationId: '' } };
        } */
        if (camera.needType === 'roadArea') {
          data = camera.check
            ? { ...data, ...{ roadId: camera.roadId } }
            : { ...data, ...{ roadId: '' } };
        } /*  else {
          data = { ...data, ...{ roadId: '' } };
        } */
        if (camera.needType === 'none') {
          data = {
            currPage: 1,
            pageSize: 24,
            cameraId: '',
            type: '',
            cameraName: '',
            regionCode: '',
            roadId: '',
            organizationId: camera.organizationId ? camera.organizationId : '',

            startTime: this.dataRange
              ? moment(this.dataRange[0]).format('YYYY-MM-DD HH:mm:ss')
              : '',
            endTime: this.dataRange
              ? moment(this.dataRange[1]).format('YYYY-MM-DD HH:mm:ss')
              : '',
          };
        }
        // console.log('papa', data);
        this.setScreenShotSearchParams(data);
        if (data.organizationId == '0000') {
          this.setScreenShotDataInfo([]);
        } else {
          this._getScreenShotList();
        }
      }
    },
    initDateRange() {
      let DateFormat = moment().format('YYYY-MM-DD');

      // this.dataRange =
      //   this.activeMenu === 0
      //     ? null
      //     : [
      //         moment(`${DateFormat} 00:00:00`),
      //         moment(`${DateFormat} 23:59:59`),
      //       ];

      this.dataRange = [
        moment(`${DateFormat} 00:00:00`),
        moment(`${DateFormat} 23:59:59`),
      ];
    },
    tabClick(index) {
      if (this.loading) {
        return;
      }
      this.activeMenu = index;
      this.pagination.current = 1;
      this.pagination.total = 0;
      //设置是否是省级
      this.setIsProvince(index === 0 ? false : true);
      if (index === 0) {
        this.initDateRange();
        this.initTabOne();
      } else {
        this.initDateRange();
        this.initTabTwo();
      }
    },
    initTabTwo() {
      this.setScreenShotProvinceParams({
        currPage: 1,
        pageSize: 24,
        startTime: this.dataRange
          ? moment(this.dataRange[0]).valueOf() / 1000
          : '',
        endTime: this.dataRange
          ? moment(this.dataRange[1]).valueOf() / 1000
          : '',
        cameraNum: '',
      });
      this._getScreenProvinceShotList();
    },
    initTabOne() {
      this.setScreenShotSearchParams({
        currPage: 1,
        pageSize: 24,
        cameraId: '',
        type: '',
        cameraName: '',
        regionCode: '',
        roadId: '',
        organizationId: '',

        startTime: this.dataRange
          ? moment(this.dataRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endTime: this.dataRange
          ? moment(this.dataRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
      });
      this._getScreenShotList();
    },

    changeDataRange(date) {
      this.dataRange = date;
    },
    selectShot(value) {
      this.shotId = value;
    },
    //查看大图
    viewDetail(index) {
      // this.imgUrl = imgUrl;
      this.curIndex = index;
      this.showAlbum = true;
    },
    onTableHandle(list) {
      let selectedKeys = list.map((item) => item.id);
      let screenShotDataInfo = this.screenShotDataInfo.map((item) => {
        if (selectedKeys.includes(item.id)) {
          item.check = true;
        } else {
          item.check = false;
        }
        return item;
      });
      this.checkAll =
        this.screenShotDataInfo.filter((item) => item.check).length ===
        this.screenShotDataInfo.length;
      let selectCameraList = screenShotDataInfo.filter((item) => item.check);
      this.setScreenShotDataInfo(screenShotDataInfo);
      this.setScreenSelectCameraList(selectCameraList);
    },
    onChange(e, index) {
      if (e.target.checked) {
        this.screenShotDataInfo[index].check = true;
        this.selectCameraList[index] = this.screenShotDataInfo[index];
      } else {
        this.screenShotDataInfo[index].check = false;
        this.selectCameraList[index] = null;
      }
      this.checkAll =
        this.screenShotDataInfo.filter((item) => item.check).length ===
        this.screenShotDataInfo.length;
      //传到表格中，让表格选项选中
      this.selectedRowKeysData = this.screenShotDataInfo
        .filter((item) => item.check)
        .map((item) => item.id);
      this.setScreenSelectCameraList(this.selectCameraList);
    },
    //图片加载后设置图片高度
    loadHandle(index) {
      const imgDom = this.$refs.imgDom[index];
      const wd = imgDom && imgDom.offsetWidth;
      this.imgHt = (3 / 4) * wd;
    },
    _getScreenShotList() {
      let data = {
        ...this.screenShotSearchParams,
        ...{ currPage: this.pagination.current },
      };
      this.loading = true;
      this.getScreenShotInfo(data).then((res) => {
        if (res.code === CODE_OK) {
          this.pagination.total = res.total;
          this.loading = false;
        }
      });
    },
    _getScreenProvinceShotList() {
      let data = {
        ...this.screenShotProvinceParams,
        ...{
          currPage: this.pagination.current,
        },
      };
      if (!data.cameraNum) {
        this.setScreenShotDataInfo([]);
        this.pagination.total = 0;
        this.setScreenTotal(0);
        return;
      }
      this.loading = true;
      this.getProvinceScreenShotInfo(data).then((res) => {
        if (res && res.code === CODE_OK) {
          this.pagination.total = res.total;
          this.loading = false;
        } else {
          this.$message.error('获取省级截图失败');
        }
      });
    },
    //切换列表缩略图显示形式
    setCurrentModeHandle(mode) {
      /* let screenShotDataInfo = this.screenShotDataInfo.map((item) => {
        item.check = false;
        return item;
      });
      this.checkAll = false;
      let selectCameraList = new Array(screenShotDataInfo.length).fill(null);
      this.setScreenShotDataInfo(screenShotDataInfo);
      this.setScreenSelectCameraList(selectCameraList); */
      this.setCurrentMode(mode);
    },
    changeCurrent(page) {
      this.pagination.current = page;
      this.resetCheckInfo();
      this.activeMenu === 0
        ? this._getScreenShotList()
        : this._getScreenProvinceShotList();
    },
    resetCheckInfo() {
      let screenShotDataInfo = this.screenShotDataInfo.map((item) => {
        item.check = false;
        return item;
      });
      this.checkAll = false;
      let selectCameraList = new Array(screenShotDataInfo.length).fill(null);
      this.setScreenShotDataInfo(screenShotDataInfo);
      this.setScreenSelectCameraList(selectCameraList);
    },
    searchHandle() {
      if (this.activeMenu === 0) {
        let data = {
          ...this.screenShotSearchParams,
          ...{
            type: this.shotId,
            cameraName: this.cameraName,
            cameraId: this.cameraId,
            startTime: this.dataRange
              ? moment(this.dataRange[0]).format('YYYY-MM-DD HH:mm:ss')
              : '',
            endTime: this.dataRange
              ? moment(this.dataRange[1]).format('YYYY-MM-DD HH:mm:ss')
              : '',
          },
        };
        this.setScreenShotSearchParams(data);
        this.getScreenShotInfo(data).then((res) => {
          if (res.code === CODE_OK) {
            this.pagination.current = 1;
            this.pagination.total = res.total;
          }
        });
      } else {
        let data = {
          ...this.screenShotProvinceParams,
          ...{
            currPage: 1,
            startTime: this.dataRange
              ? moment(this.dataRange[0]).valueOf() / 1000
              : '',
            endTime: this.dataRange
              ? moment(this.dataRange[1]).valueOf() / 1000
              : '',
          },
        };
        if (!data.cameraNum) {
          this.$message.warning('请选择摄像机后再进行查询操作');
          return;
        }
        this.setScreenShotProvinceParams(data);
        this.getProvinceScreenShotInfo(data).then((res) => {
          if (res && res.code === CODE_OK) {
            this.pagination.total = res.total;
          }
        });
      }
    },
    resetHandle() {
      this.initDateRange();
      this.shotId = '';
      this.cameraName = '';
      this.cameraId = '';
      this.searchHandle();
    },
    handleMenuClick(e) {
      if (e === '1') {
        this.detachDel();
      } else {
        this.detachDownLoad();
      }
    },

    // 批量下载
    detachDownLoad() {
      if (this.activeMenu === 0) {
        // 我
        if (!this.hasPermission(109211111011)) {
          this.permissionWarning();
          return;
        }
      } else if (this.activeMenu === 1) {
        // 部
        if (!this.hasPermission(109211111112)) {
          this.permissionWarning();
          return;
        }
      }

      let snapshotArr = this.selectCameraList
        .filter((item) => !!item)
        .map((item) => ({
          fileType: item.fileType,
          snapshotName: item.snapshotName,
          snapshotUrl: item.snapshotUrl,
        }));
      if (snapshotArr.length <= 0) {
        this.$message.warning('请选择截图');
        return;
      }
      let cameraNames = this.selectCameraList
        .filter((item) => !!item)
        .map((item) => item.cameraName)
        .join('|');
      let objParams = {
        data: snapshotArr,
        instructionsDel: {
          module: '截图和录像',
          page: '截图管理',
          feature: '批量下载截图',
          description: `批量下载截图: ${cameraNames || '暂无名称'}`,
        },
      };
      this.$api.snapshot.batchDownload(objParams).then((res) => {
        handleZipExportFile(res, '批量截图下载.zip');
      });
      /*  snapshotUrls.map((item, index) => {
        this.imgDown(item, `图片${index + 1}`);
      }); */
    },

    // 批量删除
    detachDel() {
      if (this.activeMenu === 0) {
        // 我
        if (!this.hasPermission(109211111013)) {
          this.permissionWarning();
          return;
        }
      } else if (this.activeMenu === 1) {
        // 部
        if (!this.hasPermission(109211111114)) {
          this.permissionWarning();
          return;
        }
      }

      let ids = this.selectCameraList
        .filter((item) => !!item)
        .map((item) => ({
          id: item.id,
          userId: item.gmtCreateUid,
          fileSize: item.fileSize,
        }));
      if (ids.length <= 0) {
        this.$message.warning('请选择截图');
        return;
      }
      let cameraNames = this.selectCameraList
        .filter((item) => !!item)
        .map((item) => item.cameraName)
        .join('|');
      let data = {
        instructionsDel: {
          module: '截图和录像',
          page: '截图管理',
          feature: '删除截图',
          description: `批量删除截图: ${cameraNames || '暂无名称'}`,
        },
        ids,
      };
      this.$confirm({
        title: '提示',
        content: '你确定删除该截图吗?',
        onOk: () => {
          this.deleteScreenShout(data).then((res) => {
            if (res.code === 200) {
              this.$message.success(`删除${ids.length}条记录成功`);
              this.searchHandle();
            }
          });
        },
        onCancel: () => {},
      });
    },
    downloadImage(imgsrc) {
      fetch(imgsrc)
        .then(function(res) {
          return res.blob();
        })
        .then((data) => {
          let img = document.createElement('img');
          img.onload = function() {
            window.URL.revokeObjectURL(img.src);
          };
          img.src = window.URL.createObjectURL(data);
          this.downloadWBImage(img.src);
        });
    },
    downloadWBImage(imgSrc) {
      // 下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function() {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/jpeg'); // 得到图片的base64编码数据
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '摄像机截图'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgSrc;
    },
    onCheckAllChange(e) {
      let selectCameraList = null;
      this.checkAll = e.target.checked;
      let screenShotDataInfo = null;
      if (e.target.checked) {
        screenShotDataInfo = this.screenShotDataInfo.map((item) => {
          item.check = true;
          return item;
        });
        selectCameraList = this.screenShotDataInfo;
      } else {
        screenShotDataInfo = this.screenShotDataInfo.map((item) => {
          item.check = false;
          return item;
        });
        selectCameraList = new Array(this.screenShotDataInfo.length).fill(null);
      }
      //传到表格中，让表格选项选中
      this.selectedRowKeysData = screenShotDataInfo
        .filter((item) => item.check)
        .map((item) => item.id);
      this.setScreenShotDataInfo(screenShotDataInfo);
      this.setScreenSelectCameraList(selectCameraList);
    },
    handleImageAnalysis() {
      let cameraList = this.selectCameraList.filter((item) => !!item);
      if (cameraList.length <= 1) {
        this.$message.warning('请至少选择两张图片');
        return;
      }
      if (!this.isContinue(cameraList)) {
        this.$message.warning('请保证选择的图片是连续的');
        return;
      }
      this.manualCameraList = cameraList;
      this.imageAnalysisVisible = true;
    },
    closeImageAnHandle() {
      this.imageAnalysisVisible = false;
    },
    isContinue(cameraList) {
      let bool = true;
      for (let i = 0; i < cameraList.length - 1; i++) {
        if (cameraList[i + 1].index - cameraList[i].index !== 1) {
          bool = false;
          break;
        }
      }
      return bool;
    },
    sureImageAnHandle() {
      let screenShotDataInfo = this.screenShotDataInfo.map((item) => {
        item.check = false;
        return item;
      });
      let selectCameraList = new Array(this.screenShotDataInfo.length).fill(
        null
      );

      this.setScreenShotDataInfo(screenShotDataInfo);
      this.setScreenSelectCameraList(selectCameraList);
      this.closeImageAnHandle();
      //跳转到分析页面
      this.$parent.activeMenu = 'ImageAn';
    },
    /*     downloadImage2(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      // image.setAttribute("crossOrigin", "anonymous");
      image.crossOrigin = 'Anonymous';
      image.src = imgsrc;
      image.onload = function() {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/jpg'); //得到图片的base64编码数据

        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = name || '摄像机截图'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      // let fileSize = image.fileSize;
      // eslint-disable-next-line no-unused-vars

    }, */
  },
};
</script>

<style scoped>
.ht {
  height: calc(100% - 30px);
}
.f-l {
  width: 320px;
}
.list-wrap > div {
  width: 16.6%;
}
.list-wrap .list-item {
  margin: 0 10px 5px;
  /*  height: 96px;
  overflow: hidden; */
}
.list-wrap >>> .ant-checkbox-inner {
  background-color: #031029;
  border-color: #b8cce6;
}
.list-wrap .list-item .time {
  left: 5px;
  bottom: 5px;
}
/*.list-item-wrap {
 height: 136px; 
}*/
.t6 {
  top: 6px;
}
.l6 {
  left: 8px;
}
.b10 {
  bottom: 10px;
}
.r4 {
  right: 4px;
}
.mode-style .current {
  color: rgba(11, 99, 250, 1);
}
.cursor-pointer {
  cursor: not-allowed;
}
</style>
