<template>
  <div>
    <transition name="animate">
      <div class="snapshot-detail" v-show="visible">
        <div class="mask" @click="closeHandle"></div>
        <div class="snapshot-con p-sm">
          <div class="snap-title flex justify-between m-b-sm">
            <span class="flex"
              >抓拍详情<i
                class="text-lightblue pointer download m-l-xs"
                v-show="!!currentSnap.snapshotUrl"
                @click="downloadImage(currentSnap)"
              ></i
            ></span>
            <span class="weight f20 pointer close" @click="closeHandle">×</span>
          </div>
          <div class="snap-con flex">
            <div
              class="snap-con-inner relative flex-one flex items-center justify-center full-height  m-r-sm"
            >
              <span
                class="cur-prev f16 pointer absolute flex items-center justify-center"
                @click="prev"
                ><a-icon type="left"
              /></span>
              <span
                class="cur-next f16 pointer absolute flex items-center justify-center"
                @click="next"
                ><a-icon type="right"
              /></span>
              <template>
                <div
                  class="snap-img full-height full-width flex items-center justify-center"
                  @click="showAlbum = true"
                  v-show="!loading"
                >
                  <img
                    :src="currentSnap.snapshotUrl || currentSnapShotUrl"
                    class="img-style"
                  />
                </div>
                <a-spin v-show="loading"></a-spin>
              </template>
            </div>
            <div class="snap-right">
              <title-com text="抓拍信息" class="m-b-sm"></title-com>
              <div class="flex items-center">
                <span class="text-white">名称：</span>
                <span class="ellipsis text-info">{{
                  currentSnap.snapshotName ||
                    currentSnap.snapshotTime ||
                    currentSnapNameNull
                }}</span>
              </div>
              <div class="flex items-center p-h-sm bd-b m-b-xs" v-if="active === 0">
                <span class="text-white">格式：</span>
                <span class="ellipsis text-info">{{
                  currentSnap.fileType || 'JPEG'
                }}</span>
              </div>
              <div class="flex items-center p-h-xs">
                <span class="text-white">归属摄像机：</span>
                <span
                  class="ellipsis text-info"
                  :title="currentSnap.cameraName"
                  >{{ currentSnap.cameraName }}</span
                >
              </div>
              <div class="flex items-center p-h-xs">
                <span class="text-white">摄像机类型：</span>
                <span class="text-info">{{ currentSnap.classify }}</span>
              </div>
              <div class="flex items-center p-h-xs">
                <span class="text-white">归属区域：</span>
                <span
                  class="text-info ellipsis"
                  :title="currentSnap.regionName"
                  >{{ currentSnap.regionName }}</span
                >
              </div>
              <div class="flex items-center p-h-xs">
                <span class="text-white">经纬度：</span>
                <span
                  class="text-info ellipsis"
                  :title="currentSnap.longAndlat"
                  >{{ currentSnap.longAndlat }}</span
                >
              </div>
              <div class="flex items-center p-h-xs">
                <span class="text-white">抓拍者：</span>
                <span class="text-info ellipsis">{{
                  currentSnap.userName
                }}</span>
              </div>
              <div class="flex items-center p-h-xs">
                <span class="text-white">抓拍时间：</span>
                <span class="text-info ellipsis">{{
                  currentSnap.snapshotTime || currentSnapNameNull
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <album
      :url="currentSnap.snapshotUrl || currentSnapShotUrl"
      :visible="showAlbum"
      @close="showAlbum = false"
    />
  </div>
</template>

<script>
import TitleCom from '@/components/TitleCom';
import Album from './Album';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'SnapShotDetail',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    curIndex: {
      type: Number,
      default: -1,
    },
    snapList: {
      type: Array,
      default() {
        return [];
      },
    },
    downloadImageParent: {
      type: Function,
      default() {
        return () => {};
      },
    },
    active:{
      type:[Number,String]
    }
  },
  components: {
    TitleCom,
    Album,
  },
  data() {
    return {
      currentIndex: this.curIndex,
      showAlbum: false,
      currentSnap: {},
      loading: false,
      /*  snapInfo: [
        {
          name: 'snapName',
          value: '截图保存的名称',
        },
        {
          name: 'snapFormater',
          value: 'jpg',
        },
        {
          name: 'snapAddress',
          value: 'S11(宜毕高速)云南省昭通市镇',
        },
        {},
      ], */
    };
  },
  computed: {
    /*  currentSnap() {
       return this.snapList[this.currentIndex] || {};
    }, */
    currentId() {
      // console.log(this.snapList, this.snapList[this.currentIndex]);
      return this.snapList[this.currentIndex]
        ? this.snapList[this.currentIndex]['id']
        : '';
    },
    currentCameraNum() {
      return this.snapList[this.currentIndex]
        ? this.snapList[this.currentIndex]['cameraNum']
        : '';
    },
    currentSnapNameNull() {
      return this.snapList[this.currentIndex]
        ? this.snapList[this.currentIndex]['snapshotTime']
        : '';
    },
    currentSnapShotUrl() {
      return this.snapList[this.currentIndex]
        ? this.snapList[this.currentIndex]['snapshotUrl']
        : '';
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && newVal != oldVal) this.currentIndex = this.curIndex;
      this.getCurSnap();
    },
  },
  methods: {
    closeHandle() {
      this.$emit('close');
    },
    prev() {
      if (this.currentIndex <= 0) {
        this.$message.warning('已经是本页第一张！');
        return;
      }
      this.currentIndex--;
      this.getCurSnap();
    },
    next() {
      if (this.currentIndex >= this.snapList.length - 1) {
        this.$message.warning('已经是本页最后一张了！');
        return;
      }
      this.currentIndex++;
      this.getCurSnap();
    },
    getCurSnap() {
      let params = {};
      if (this.currentId) {
        params['id'] = this.currentId;
      } else {
        params['cameraNum'] = this.currentCameraNum;
      }
      this.loading = true;
      this.currentSnap = {};
      this.$api.snapshot.getScreenShoutCamera(params).then((res) => {
        if (res.code === CODE_OK) {
          this.currentSnap = res.data;
          this.loading = false;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    downloadImage(record) {
      this.downloadImageParent(record);
      // console.log('haha', imgsrc);
      /* fetch(imgsrc)
        .then(function(res) {
          return res.blob();
        })
        .then((data) => {
          let img = document.createElement('img');
          img.onload = function() {
            window.URL.revokeObjectURL(img.src);
          };
          img.src = window.URL.createObjectURL(data);
          this.downloadWBImage(img.src);
        }); */
    },
    downloadWBImage(imgSrc) {
      // 下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function() {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/jpeg'); // 得到图片的base64编码数据
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '摄像机截图'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgSrc;
    },
  },
};
</script>

<style scoped>
.mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.animate-enter-active,
.animate-leave-active {
  transition: all 0.5s;
}
.animate-enter,
.animate-leave-to {
  opacity: 0;
}

.snapshot-detail {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}
.snapshot-con {
  position: absolute;
  width: 60%;
  max-width: 750px;
  height: 58%;
  max-height: 340px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /*   transform: translateZ(-50%, -50%, 0); */
  z-index: 999;
  background: #0c2142;
  box-shadow: 0 0 10px 2px #4f97f8;
  overflow: hidden;
}
.snap-con {
  height: calc(100% - 40px);
}
.animate-enter-active .snapshot-con,
.animate-leave-active .snapshot-con {
  transition: all 0.5s;
}
.animate-enter .snapshot-con,
.animate-leave-to .snapshot-con {
  transform: translate(-50%, 100%);
}
.close {
  line-height: 1;
}
.snap-right {
  flex: 0 0 36%;
}
.snap-right .text-white {
  flex: 0 0 90px;
  text-align: right;
}
.cur-prev,
.cur-next {
  background: rgba(0, 0, 0, 0.4);
  width: 30px;
  height: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}
.cur-prev {
  left: 20px;
}
.cur-next {
  right: 20px;
}
.img-style {
  max-width: 100%;
  max-height: 100%;
}
.text-info {
  width: 200px;
}
</style>
