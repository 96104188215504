<template>
  <div class="full-width full-height bg-blue  text-lightblue p-md">
    <div class="flex justify-between justify-center m-b-sm">
      <back url="/resource/index" />
      <div class="text-lightblue">
        <span class="m-r-md" v-show="false">可用空间: 200T</span>
        <span v-if="false">已用空间: 160T</span>
        <span v-show="false">剩余空间: 40T</span>
      </div>
    </div>
    <div class="fl-menu flex items-center m-b-sm" v-show="$g.isDepart">
      <span
        :class="[{ current: activeMenu === 'Screen' }, 'pointer']"
        @click="activeMenu = 'Screen'"
        >截图管理</span
      >
      <span
        :class="[{ current: activeMenu === 'Video' }, 'pointer']"
        @click="activeMenu = 'Video'"
        >录像管理</span
      >
      <span
        :class="[{ current: activeMenu === 'ImageAn' }, 'pointer']"
        @click="activeMenu = 'ImageAn'"
        >图像分析</span
      >
    </div>
    <!--  <div class="flex items-center flex-end m-t-sm m-b-sm">
      <div class="flex items-center" v-if="activeMenu === 'Screen'">
        <div class="m-r-sm text-lightblue flex items-center" v-show="false">
          <span>截图人：</span>
          <a-select v-model="shotId" style="width: 140px;" @change="selectShot">
            <a-select-option
              v-for="item in shotList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="m-r-sm text-lightblue flex items-center">
          <span>截图方式：</span>
          <a-select v-model="shotId" style="width: 140px;" @change="selectShot">
            <a-select-option
              v-for="item in shotList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="m-r-sm text-lightblue" v-show="false">
          <a-input placeholder="请输入摄像机名称" v-model="cameraName" />
        </div>
        <div class="m-r-sm text-lightblue flex items-center">
          <span>截图时间：</span>
          <a-range-picker
            v-model="dataRange"
            :placeholder="['开始时间', '结束时间']"
            @change="changeDataRange"
            format="YYYY-MM-DD HH:mm:ss"
            show-time
          ></a-range-picker>
        </div>
        <a-button
          type="primary"
          class="m-r-sm btn-search"
          @click="searchHandle"
        >
          搜索
        </a-button>
        <a-button class="btn-reset" @click="resetHandle">
          重置
        </a-button>
        <span class="span-line m-l-md m-r-md"></span>
        <div class="m-r-sm">
          <a-dropdown>
            <a-button class="btn-export">
              批量处理
            </a-button>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="1">删除</a-menu-item>
              <a-menu-item key="2">下载</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="f20 mode-style">
          <a-icon
            type="fund"
            class="m-r-xs pointer"
            :class="{ current: currentMode === 'pic' }"
            @click="setCurrentMode('pic')"
          />
          <a-icon
            type="unordered-list"
            class="pointer"
            :class="{ current: currentMode === 'list' }"
            @click="setCurrentMode('list')"
          />
        </div>
      </div>
    </div> -->
    <component :is="activeMenu" />
  </div>
</template>

<script>
import Back from '@/components/Back';
import Screen from './components/Screen';
import Video from './components/Video';
import ImageAn from './components/ImageAn';
import moment from 'moment';
import { mapState, mapMutations, mapActions } from 'vuex';
import { handleZipExportFile } from '@/tools';
export default {
  name: 'ScreenAndVideo',
  data() {
    return {
      activeMenu: 'Screen',
      cameraName: '',
      shotList: [
        {
          id: 0,
          value: '',
          name: '全部',
        },
        {
          id: 2,
          value: 2,
          name: '手动截图',
        },
        {
          id: 1,
          value: 1,
          name: '自动截图',
        },
      ],
      shotId: '',
      dataRange: null,
    };
  },
  components: {
    Back,
    Screen,
    Video,
    ImageAn,
  },
  computed: {
    ...mapState({
      screenShotSearchParams: state => state.resource.screenShotSearchParams,
      selectCameraList: state => state.resource.selectCameraList,
      currentMode: state => state.resource.currentMode,
    }),
  },
  watch: {},
  methods: {
    ...mapMutations(['setScreenShotSearchParams', 'setCurrentMode']),
    ...mapActions(['getScreenShotInfo', 'deleteScreenShout']),
    selectShot(value) {
      this.shotId = value;
    },
    changeDataRange(date) {
      this.dataRange = date;
    },
    searchHandle() {
      let data = {
        ...this.screenShotSearchParams,
        ...{
          type: this.shotId,
          cameraName: this.cameraName,
          cameraId: this.cameraId,
          startTime: this.dataRange
            ? moment(this.dataRange[0]).format('YYYY-MM-DD HH:mm:ss')
            : '',
          endTime: this.dataRange
            ? moment(this.dataRange[1]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        },
      };
      this.setScreenShotSearchParams(data);
      this.getScreenShotInfo(data);
    },
    resetHandle() {
      this.dataRange = null;
      this.shotId = '';
      this.cameraName = '';
      this.cameraId = '';
      this.searchHandle();
    },
    handleMenuClick(e) {
      if (e.key === '1') {
        this.detachDel();
      } else {
        this.detachDownLoad();
      }
    },
    detachDel() {
      // console.error(this.selectCameraList);

      let ids = this.selectCameraList
        .filter(item => !!item)
        .map(item => item.id);
      if (ids.length <= 0) {
        this.$message.waring('请选择截图');
        return;
      }
      this.$confirm({
        title: '提示',
        content: '你确定删除该截图吗?',
        onOk: () => {
          this.deleteScreenShout(ids).then(res => {
            if (res.code === 200) {
              this.$message.success(`删除${ids.length}条记录成功`);
              this.searchHandle();
            }
          });
        },
        onCancel: () => {},
      });
    },
    detachDownLoad() {
      let snapshotArr = this.selectCameraList
        .filter(item => !!item)
        .map(item => ({
          fileType: item.fileType,
          snapshotName: item.snapshotName,
          snapshotUrl: item.snapshotUrl,
        }));
      if (snapshotArr.length <= 0) {
        this.$message.warning('请选择截图');
        return;
      }
      this.$api.snapshot.batchDownload(snapshotArr).then(res => {
        handleZipExportFile(res, '批量截图下载.zip');
      });
      /*  snapshotUrls.map((item, index) => {
        this.imgDown(item, `图片${index + 1}`);
      }); */
    },
    imgDown(url, filename) {
      var save_link = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'a'
      );
      save_link.href = url;
      save_link.download = filename;
      save_link.target = '_blank';
      var event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      save_link.dispatchEvent(event);
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function() {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL('image/png'); //得到图片的base64编码数据
        let a = document.createElement('a'); // 生成一个a元素
        let event = new MouseEvent('click'); // 创建一个单击事件
        a.download = name || 'photo'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
  },
  created() {
    this.activeMenu = this.$route.params.tab || 'Screen';
  },
};
</script>

<style scoped></style>
