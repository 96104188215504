<template>
  <a-modal
    title="智能分析"
    :visible="visible"
    width="30%"
    :centered="true"
    @cancel="handleCancel"
    @ok="sureHandle"
  >
    <div class="flex items-center m-b-sm">
      <span class="flex-s m-r-xs title text-right"
        ><span class="text-red">*</span>摄像头：</span
      >
      <span class="text-lightblue flex items-center">
        <a-icon type="plus-circle" class="m-r-sm" @click="addHandle" />已选
        <span class="text-line m-l-xs m-r-xs">{{
          selectCameraList ? selectCameraList.length : 0
        }}</span>
        路摄像机</span
      >
    </div>
    <div class="flex items-center m-b-sm">
      <span class="flex-s m-r-xs title text-right">截图开始：</span>
      <a-date-picker
        v-model="datePicker"
        show-time
        class="flex-one"
        placeholder="请选择截图开始时间"
        @change="onDateChange"
        :allowClear="false"
      />
    </div>
    <div class="flex m-b-sm">
      <span class="flex-s m-r-xs title text-right">截图结束：</span>
      <div class="flex-one">
        <a-radio-group class="m-b-sm" name="radioGroup" v-model="screenshotEnd">
          <a-radio :value="1">
            时长
          </a-radio>
          <a-radio :value="2">
            停止
          </a-radio>
        </a-radio-group>
        <div v-if="screenshotEnd === 1">
          <a-select
            v-model="turnDateNewHour"
            @change="handleHourChange"
            class="m-r-xs wd"
          >
            <a-select-option
              v-for="item in hourArr"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
          <span class="m-r-xs m-l-xs">时</span>
          <a-select
            v-model="turnDateNewMinute"
            @change="handleMinuteChange"
            class="wd"
          >
            <a-select-option
              v-for="item in minuteArr"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
          <span class="m-l-xs m-r-xs">分</span>
          <a-select
            v-model="turnDateNewSecond"
            @change="handleSecondChange"
            class="wd"
          >
            <a-select-option
              v-for="item in secondArr"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
          <span class="m-l-xs">秒</span>
        </div>
        <div v-else>
          <a-date-picker
            v-model="datePickerEnd"
            show-time
            class="flex-one"
            placeholder="请选择截图结束时间"
            @change="onDateEndChange"
            :allowClear="false"
          />
        </div>
      </div>
    </div>
    <cameral-select
      :visible="cameraSelectVisible"
      :resource="resource"
      @closeCameralDialog="closeCameralDialogHandle"
    />
  </a-modal>
</template>

<script>
import CameralSelect from '@/components/CameralSelect';
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import { CODE_OK } from '@/request/config_code';
import { getTimes } from '@/tools/';
export default {
  name: 'IntelliAn',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cameraSelectVisible: false,
      resource: false,
      datePicker: moment(),
      screenshotEnd: 1,
      turnDateNewHour: '00', //'08',
      turnDateNewMinute: '05', //'40',
      turnDateNewSecond: '00',
      hourArr: (() => {
        return getTimes(24);
      })(),
      minuteArr: (() => {
        return getTimes(60);
      })(),
      secondArr: (() => {
        return getTimes(60);
      })(),
      datePickerEnd: (() => {
        this.getNextTime();
      })(),
    };
  },
  computed: {
    ...mapState({
      selectCameraList: (state) => state.collect.selectCameraList,
    }),
  },
  watch: {
    screenshotEnd(nv, ov) {
      if (nv !== ov) {
        //初始化时间
        this.initTime();
      }
    },
  },
  methods: {
    ...mapMutations(['setCurrentComIndex', 'setSelectCameraList']),
    initTime() {
      this.turnDateNewHour = '00'; //'08',
      this.turnDateNewMinute = '05'; //'40',
      this.turnDateNewSecond = '00';
      this.datePickerEnd = this.getNextTime();
    },
    //当前时间的后五分钟
    getNextTime() {
      let now = +new Date();
      let afterNow = now + 5 * 60 * 1000;
      return moment(afterNow);
    },
    sureHandle() {
      let snapshotEndTime =
        this.screenshotEnd === 1
          ? this.getSnapTime()
          : moment(this.datePickerEnd).format('YYYY-MM-DD HH:mm:ss');
      let params = {
        cameraNums: this.selectCameraList.map((item) => item.cameraNum),
        snapshotStartTime: moment(this.datePicker).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        snapshotEndTime,
      };
      if (!params.cameraNums.length) {
        this.$message.warn('请选择需要分析的摄像机！');
        return;
      }
      console.log(params);
      this.$api.imageAn.postIntelligent(params).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('新增智能分析成功');
          this.resetData();
          this.$emit('sure');
        } else {
          this.$message.error('新增智能分析出错');
        }
      });
    },
    getSnapTime() {
      let endTime = '';
      let startime = moment(this.datePicker);
      let turnHour = this.getNumber(this.turnDateNewHour);
      let turnMin = this.getNumber(this.turnDateNewMinute);
      let turnSec = this.getNumber(this.turnDateNewSecond);
      let duration =
        turnHour * 60 * 60 * 1000 + turnMin * 60 * 1000 + turnSec * 1000;
      endTime = startime + duration;
      return moment(endTime).format('YYYY-MM-DD HH:mm:ss');
    },
    getNumber(str) {
      return +str.replace(/\b(0)/, '');
    },
    resetData() {
      this.datePicker = moment();
      this.turnDateNewHour = '00'; //'08',
      this.turnDateNewMinute = '05'; //'40',
      this.turnDateNewSecond = '00';
      this.screenshotEnd = 1;
      this.datePickerEnd = this.getNextTime();
      this.setSelectCameraList([]);
    },
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
    addHandle() {
      this.cameraSelectVisible = true;
      //设置默认数据
      this.setCurrentComIndex(0);
    },
    closeCameralDialogHandle() {
      this.cameraSelectVisible = false;
    },
    onDateChange(value) {
      this.datePicker = value;
    },
    handleHourChange(value) {
      this.turnDateNewHour = value;
    },
    handleMinuteChange(value) {
      this.turnDateNewMinute = value;
    },
    handleSecondChange(value) {
      this.turnDateNewSecond = value;
    },
    onDateEndChange(value) {
      this.datePickerEnd = value;
    },
  },
  components: {
    CameralSelect,
  },
};
</script>

<style scoped>
.title {
  flex: 0 0 90px;
}
.wd {
  width: 22%;
}
</style>
