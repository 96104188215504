<template>
  <a-modal
    title="视频转码"
    :visible="visible"
    width="34%"
    :centered="true"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="flex items-center m-b-sm">
      <div class="flex items-center flex-one">
        <span class="flex-s m-r-xs title">输出格式:</span>
        <a-select v-model="currentVideoType" class="flex-one">
          <a-select-option
            :value="item.value"
            :key="item.id"
            v-for="item in videoType"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex items-center flex-one m-l-sm">
        <span class="flex-s m-r-xs title">分辨率:</span>
        <a-select v-model="currentResolution" class="flex-one">
          <a-select-option
            :value="item.value"
            :key="item.id"
            v-for="item in resolutions"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="flex items-center m-b-sm">
      <div class="flex items-center flex-one">
        <span class="flex-s m-r-xs title">编码方式:</span>
        <a-select v-model="currentEncodeType" class="flex-one">
          <a-select-option
            :value="item.value"
            :key="item.id"
            v-for="item in encodeWay"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex items-center flex-one m-l-sm">
        <span class="flex-s m-r-xs title">帧率设置:</span>
        <a-select v-model="currentFrame" class="flex-one">
          <a-select-option
            :value="item.value"
            :key="item.id"
            v-for="item in frameRate"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center flex-one">
        <span class="flex-s m-r-xs title">码率设置:</span>
        <a-select v-model="currentFrame" class="flex-one">
          <a-select-option
            :value="item.value"
            :key="item.id"
            v-for="item in codeRate"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex items-center flex-one m-l-sm">
        &nbsp;
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  name: 'VideoCompress',
  data() {
    return {
      confirmLoading: false,
      currentVideoType: 0,
      currentResolution: 0,
      exportContent: 1,
      exportFormat: 1,
      currentEncodeType: 0,
      videoType: [
        {
          id: 0,
          value: 0,
          text: 'MP4',
        },
        {
          id: 1,
          value: 1,
          text: 'MOV',
        },
        {
          id: 2,
          value: 2,
          text: 'FLV',
        },
        {
          id: 3,
          value: 3,
          text: 'AVI',
        },
        {
          id: 4,
          value: 4,
          text: 'M4V',
        },
      ],
      resolutions: [
        {
          id: 0,
          value: 0,
          text: '同源文件',
        },
        {
          id: 1,
          value: 1,
          text: '1920*1080(1080P)',
        },
        {
          id: 2,
          value: 2,
          text: '1280*720(720P)',
        },
        {
          id: 3,
          value: 3,
          text: '960*640(640P)',
        },
        {
          id: 4,
          value: 4,
          text: '640*480(480P)',
        },
      ],
      encodeWay: [
        {
          id: 0,
          value: 0,
          text: 'H264',
        },
        {
          id: 1,
          value: 1,
          text: 'H265',
        },
      ],
      frameRate: [
        {
          id: 0,
          value: 0,
          text: '15ftps',
        },
        {
          id: 1,
          value: 1,
          text: '25ftps',
        },
        {
          id: 2,
          value: 2,
          text: '30ftps',
        },
        {
          id: 3,
          value: 3,
          text: '45ftps',
        },
        {
          id: 4,
          value: 4,
          text: '60ftps',
        },
      ],
      codeRate: [
        {
          id: 0,
          value: 0,
          text: '750k',
        },
        {
          id: 1,
          value: 1,
          text: '800k',
        },
        {
          id: 2,
          value: 2,
          text: '1M',
        },
        {
          id: 3,
          value: 3,
          text: '1.5M',
        },
        {
          id: 4,
          value: 4,
          text: '2M',
        },
      ],
      currentFrame: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleOk() {
      this.$emit('sure');
    },
  },
};
</script>

<style scoped>
.title {
  width: 70px;
  text-align: right;
}
</style>
