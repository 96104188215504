<template>
  <div class="full-width ht">
    <div class="on-con relative p-md full-height">
      <span class="cor absolute t-lf"></span>
      <span class="cor absolute t-rf"></span>
      <span class="cor absolute b-lf"></span>
      <span class="cor absolute b-rf"></span>
      <div class="con-main flex items-center full-height">
        <div class="f-l full-height m-r-md">
          <cameral-route :mode="1" @postCamera="postCameraHandle" />
        </div>
        <div class="f-r full-height">
          <div class="flex items-center flex-wrap">
            <div
              class="m-r-sm text-lightblue flex items-center  m-b-sm search-mx-wd-2"
            >
              <span class="flex-s m-r-xs">录制人：</span>
              <!-- @change="handleAccountChange" -->
              <a-select
                v-model="curAccount"
                class="flex-one"
                placeholder="请选择录制人"
              >
                <a-select-option
                  v-for="item in account"
                  :value="item.userId"
                  :key="item.userId"
                >
                  {{ item.loginName }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="m-r-sm  text-lightblue flex items-center m-b-sm search-mx-wd-2"
            >
              <span class="flex-s m-r-xs">来源：</span>
              <a-select
                v-model="currentVideoSource"
                placeholder="请选择来源"
                class="flex-one"
              >
                <a-select-option
                  v-for="item in videoSource"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="m-r-sm flex items-center text-lightblue  m-b-sm search-mx-wd-2"
            >
              <span class="flex-s m-r-xs">录像名称：</span>
              <a-input
                placeholder="请输入录像名称"
                class="flex-one"
                v-model="videoName"
              />
            </div>
            <div
              class="m-r-sm text-lightblue flex items-center m-b-sm search-date-picker-tit"
            >
              <span class="flex-s m-r-xs">截图时间：</span>
              <a-range-picker
                v-model="dataRange"
                :placeholder="['开始时间', '结束时间']"
                @change="changeDataRange"
                format="YYYY-MM-DD HH:mm:ss"
                class="flex-one"
                show-time
              ></a-range-picker>
            </div>
            <a-button
              type="primary"
              class="m-r-sm btn-search  m-b-sm"
              @click="searchHandle"
            >
              搜索
            </a-button>
            <a-button class="btn-reset  m-b-sm" @click="resetHandle">
              重置
            </a-button>
          </div>

          <!-- :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }" -->
          <a-table
            :columns="columns"
            :rowKey="(record, index) => record.id"
            :data-source="list"
            :pagination="false"
            :loading="loading"
            :components="resibleTableHeader"
            bordered
            :scroll="scroll"
          >
            <template slot="customOpe" slot-scope="text, record, index">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>播放</span>
                </template>
                <a-icon
                  type="youtube"
                  class="m-r-sm"
                  @click="viewVideo(record, index)"
                />
              </a-tooltip>
              <a-tooltip placement="top" v-if="false">
                <template slot="title">
                  <span>编辑</span>
                </template>
                <a-icon
                  type="edit"
                  class="m-r-sm"
                  @click="editHandle(record)"
                />
              </a-tooltip>
              <a-tooltip placement="top" v-if="false">
                <template slot="title">
                  <span>压缩</span>
                </template>
                <a-icon
                  type="profile"
                  class="m-r-sm"
                  @click="compressHandle(record)"
                />
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>下载</span>
                </template>
                <a-icon
                  type="vertical-align-bottom"
                  class="m-r-sm"
                  @click="downloadHandle(record)"
                />
              </a-tooltip>
              <template>
                <span v-show="record.videotapeSource == 3" class="pointer">
                  <template>
                    <a-tooltip placement="top" v-if="record.saveSign === 0">
                      <template slot="title">
                        <span>保存</span>
                      </template>
                      <a-icon
                        type="tablet"
                        class="m-r-sm"
                        @click="saveHandle(record)"
                      />
                    </a-tooltip>
                    <a-tooltip
                      placement="top"
                      v-else-if="record.saveSign === 1"
                    >
                      <template slot="title">
                        <span>删除</span>
                      </template>
                      <a-icon type="rest" @click="deleteHandle(record)" />
                    </a-tooltip>
                  </template>
                </span>
              </template>
              <a-tooltip placement="top" v-show="record.videotapeSource != 3">
                <template slot="title">
                  <span>删除</span>
                </template>
                <a-icon type="rest" @click="deleteHandle(record)" />
              </a-tooltip>
            </template>
          </a-table>
          <div class="m-t-sm flex items-center justify-between">
            <div class="p-w-md">
              <!-- <a-checkbox
                v-model="checkAll"
                :disabled="list.length <= 0"
                class="m-r-xs"
              >
                全选
              </a-checkbox>
              <span class="pointer" @click="transcodeHandle" v-if="false"
                >转码</span
              > -->
            </div>
            <div class="flex items-center">
              <span class="m-r-xs">共有{{ pagination.total }}条</span>
              <a-pagination
                :current="pagination.current"
                :total="pagination.total"
                :pageSize="pagination.pageSize"
                show-quick-jumper
                @change="changeCurrent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <video-compress
      :visible="visibleCompress"
      @close="closeCompressHandle"
      @sure="sureCompressHandle"
    />
    <video-transcoding
      :visible="visibleTranscoding"
      @close="closeTranscodingHandle"
      @sure="sureTranscodingHandle"
    ></video-transcoding>
    <video-player-dialog
      :visible="visibleVideo"
      :record="list"
      :currentIndex="currentIndex"
      :downloadFunc="downloadHandle"
      @closeVideo="closeVideoHandle"
    />
    <div class="mask fix full-width full-height l0 t0" v-show="downloading">
      <div
        class="flex items-center flex-wrap justify-center full-width full-height"
      >
        <a-spin tip="下载中..." size="large"></a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import CameralRoute from '@/components/CameralRoute';
import VideoCompress from './VideoCompress';
import VideoTranscoding from './VideoTranscoding';
import VideoPlayerDialog from '@/components/VideoPlayerDialog';
import { CODE_OK } from '@/request/config_code';
// import download from '@/tools/download';
import { handleVideo /* getFileType */ } from '@/tools';
import ResizableTable from '@/components/mixins/resizeableTable';
import moment from 'moment';
export default {
  name: 'Video',
  mixins:[ResizableTable],
  data() {
    return {
      downloading: false,
      currentVideoSource: undefined,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      videoSource: [
        {
          id: 1,
          text: '手动录像',
          value: 1,
        },
        {
          id: 2,
          text: '定时录像',
          value: 2,
        },
        {
          id: 3,
          text: '自动录像',
          value: 3,
        },
        {
          id: 4,
          text: '视频转码',
          value: 4,
        },
        {
          id: 5,
          text: '视频压缩',
          value: 5,
        },
      ],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;

          this.selectedRowKeys = [];
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.selectedRowKeys = []; //fixsxy
        },
      },
      dataRange: null,
      videoName: '',
      selectedRowKeys: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'videoIndex',
          customRender: (text, record, index) => index + 1,
          width: 70,
          ellipsis: true,
        },
        {
          title: '来源',
          dataIndex: 'videotapeSource',
          key: 'videotapeSource',
          ellipsis: true,
          customRender: (text) => {
            switch (text) {
              case 1:
                return '手动录像';
              case 2:
                return '定时录像';
              case 3:
                return '自动录像';
              case 4:
                return '视频转码';
              case 5:
                return '视频压缩';
            }
          },
          width:140
        },
        {
          title: '录像名称',
          dataIndex: 'videoTapeName',
          key: 'videoTapeName',
          ellipsis: true,
          width:140
        },
        {
          title: '录制时间',
          dataIndex: 'startTime',
          key: 'startTime',
          ellipsis: true,
          customRender: (text, record) => {
            return `${record.startTime ? record.startTime : '--'} ~ ${
              record.endTime ? record.endTime : '--'
            }`;
          },
          width:140
        },
        {
          title: '录制人',
          dataIndex: 'userName',
          key: 'userName',
          ellipsis: true,
          width:140
        },
        {
          title: '创建时间',
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          ellipsis: true,
          width:140
        },
        {
          title: '操作',
          dataIndex: 'ope',
          key: 'ope',
          scopedSlots: { customRender: 'customOpe' },
          width: 160,
          ellipsis: true,
          className:'column-center'
        },
      ],
      loading: false,
      list: [],
      visibleCompress: false,
      visibleTranscoding: false,
      visibleVideo: false,
      curAccount: undefined,
      account: [],
      currentIndex: 0,
      scroll: { y: window.innerHeight - 440 ,x:'100%'},
    };
  },
  components: {
    CameralRoute,
    VideoCompress,
    VideoTranscoding,
    VideoPlayerDialog,
  },
  computed: {
    checkAll: {
      get() {
        return (
          this.selectedRowKeys.length === this.list.length &&
          this.list.length !== 0
        );
      },
      set(value) {
        this.selectedRowKeys = value ? this.list.map((item) => item.id) : [];
      },
    },
  },
  watch: {},
  mounted() {
    this._getUser();
    this._getVideoList();
  },
  methods: {
    _getVideoList() {
      let data = {
        currPage: this.pagination.current,
        endTime: this.dataRange
          ? moment(this.dataRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        pageSize: this.pagination.pageSize,
        startTime: this.dataRange
          ? moment(this.dataRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        userId: this.curAccount,
        videotapeName: this.videoName,
        videotapeSource: this.currentVideoSource,
      };
      // console.log(data);
      this.loading = true;
      this.$api.video.getVideos(data).then((res) => {
        if (res.code === CODE_OK) {
          this.loading = false;
          this.list = res.data;
          this.pagination.total = res.total;
          // console.log(res.data);
        }
      });
    },
    _getUser() {
      this.$api.cameraGroup.getUserList().then((res) => {
        if (res.code === CODE_OK) {
          this.account = res.data;
          this.account.unshift({ userId: 'system', loginName: '系统' });
        }
      });
    },
    postCameraHandle(camera) {
      console.log(camera);
    },
    judgePlayAndDownload(record) {
      if (!record.endTime || new Date(record.endTime) >= +new Date()) {
        return false;
      }
      return true;
    },
    /*  handleAccountChange(val) {
      this.curAccount = val;
    }, */
    /* onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    }, */
    changeDataRange() {},
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    changeCurrent(page) {
      this.selectedRowKeys = [];
      this.pagination.current = page;
      this._getVideoList();
    },
    viewVideo(record, index) {
      // this.currentRecord = record;
      if (!this.judgePlayAndDownload(record)) {
        this.$message.warning('文件生成中，无法播放/下载，请稍后再试');
        return;
      }
      this.currentIndex = index;
      this.visibleVideo = true;
    },
    editHandle(record) {
      console.log(record);
    },
    compressHandle(record) {
      console.log(record);
      this.visibleCompress = true;
    },
    downloadHandle(record) {
      if (!this.hasPermission(109211111210)) {
        this.permissionWarning();
        return;
      }
      if (!this.judgePlayAndDownload(record)) {
        this.$message.warning('文件生成中，无法播放/下载，请稍后再试');
        return;
      }
      // console.log(record);
      let copyRecord = JSON.parse(JSON.stringify(record));
      delete copyRecord.gmtCreate;
      delete copyRecord.startTime;
      delete copyRecord.endTime;
      delete copyRecord.gmtModified;
      this.downloading = true;
      let objParams = {
        data: copyRecord,
        instructionsDel: {
          module: '截图和录像',
          page: '录像管理',
          feature: '下载录像',
          description: `下载录像: ${copyRecord.cameraName || '暂无内容'}`,
        },
      };
      this.$api.video.downloadVideo(objParams).then((res) => {
        handleVideo(res, `视频下载.${copyRecord.fileType}`);
        this.downloading = false;
      });
    },
    deleteHandle(record) {
      if (!this.hasPermission(109211111211)) {
        this.permissionWarning();
        return;
      }

      this.$confirm({
        title: '提示',
        content: '你确定删除该条记录吗?',
        onOk: () => {
          let obj = {
            instructionsDel: {
              module: '截图和录像',
              page: '录像管理',
              feature: '删除录像',
              description: `删除录像: ${record.videoTapeName || '暂无名称'}`,
            },
            id: {
              id: record.id,
              fileSize: record.fileSize,
              userId: record.gmtCreateUid,
            },
          };
          this.$api.video.deleteVideo(obj).then((res) => {
            if (res.code === CODE_OK) {
              this.$message.success('删除录像成功!');
              this.pagination.current = 1;
              this.pagination.defaultSize = 10;
              this._getVideoList();
            } else {
              this.$message.error('删除失败!');
            }
          });
        },
        onCancel: () => {},
      });
    },
    closeCompressHandle() {
      this.visibleCompress = false;
    },
    sureCompressHandle() {
      this.closeCompressHandle();
    },
    closeTranscodingHandle() {
      this.visibleTranscoding = false;
    },
    sureTranscodingHandle() {
      this.closeTranscodingHandle();
    },
    transcodeHandle() {
      this.visibleTranscoding = true;
    },
    closeVideoHandle() {
      this.visibleVideo = false;
    },
    searchHandle() {
      this.pagination.current = 1;
      this._getVideoList();
    },
    resetHandle() {
      this.pagination.current = 1;
      this.videoName = '';
      this.currentVideoSource = undefined;
      this.curAccount = undefined;
      this.dataRange = null;
      this._getVideoList();
    },
    saveHandle(record) {
      this.$api.snapshot.confirmSaveAutoVideo(record.id).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('保存成功');
          this._getVideoList();
        } else {
          this.$message.error('保存失败');
        }
      });
    },
  },
};
</script>

<style scoped>
.ht {
  height: calc(100% - 80px);
}
.f-l {
  width: 320px;
}
.f-r{
  width: calc(100% - 320px);
}
.list-wrap >>> .ant-checkbox-inner {
  background-color: #031029;
  border-color: #28456a;
}
.wd {
  width: 60%;
}
.wd30 {
  width: 32%;
}
.mask {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}
</style>
