<template>
  <div>
    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :data-source="data"
      :pagination="false"
      :loading="loading"
      bordered
    >
      <template slot="customType" slot-scope="text">
        {{ text === '2' ? '手动截图' : '自动截图' }}
      </template>
      <template slot="operation" slot-scope="text, records, index">
        <div class="flex items-center f16">
          <a-icon
            title="查看详情"
            type="appstore"
            class="pointer m-r-xs"
            @click="viewDetail(index)"
          />
          <a-icon
            title="下载"
            type="vertical-align-bottom"
            class="pointer m-r-xs"
            @click="downloadImage(records)"
          />
          <a-icon
            v-show="fromTab === '我的截图'"
            title="删除"
            type="rest"
            class="pointer"
            @click="del(records)"
          />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
const COLUMN = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 70,
    ellipsis: true,
  },
  {
    title: '截图方式',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    scopedSlots: { customRender: 'customType' },
  },
  {
    title: '截图名称',
    dataIndex: 'snapshotName',
    key: 'snapshotName',
    ellipsis: true,
  },
  {
    title: '格式',
    dataIndex: 'fileType',
    key: 'fileType',
    ellipsis: true,
    filter: 1,
  },
  /*  {
          title: '截图人',
          dataIndex: 'userName',
          key: 'userName',
          ellipsis: true,
          width: 90,
        }, */
  {
    title: '创建时间',
    dataIndex: 'snapshotTime',
    key: 'snapshotTime',
    ellipsis: true,
  },

  {
    title: '操作',
    dataIndex: 'ope',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 100,
  },
];
export default {
  name: 'ListTable',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    downloadImageParent: {
      type: Function,
      default() {
        return () => {};
      },
    },
    viewDetailParent: {
      type: Function,
      default() {
        return () => {};
      },
    },
    selectedRowKeysProp: {
      type: Array,
      default() {
        return [];
      },
    },
    fromTab: {
      type: String,
      default: '我的截图',
    },
  },
  data() {
    return {
      selectedRowKeys: this.selectedRowKeysProp,
      columns: COLUMN,
    };
  },
  computed: {
    ...mapState({
      screenShotSearchParams: (state) => state.resource.screenShotSearchParams,
    }),
  },
  watch: {
    fromTab(nv) {
      this.formatColum(nv);
    },
    selectedRowKeysProp(nv, ov) {
      if (nv != ov) {
        this.selectedRowKeys = nv;
      }
    },
  },
  created() {
    this.formatColum(this.fromTab);
  },
  methods: {
    ...mapMutations(['setScreenShotSearchParams', 'setSelectCameraList']),
    ...mapActions(['getScreenShotInfo', 'deleteScreenShout']),
    formatColum(nv) {
      if (nv === '我的截图') {
        this.columns = COLUMN;
      } else {
        this.columns = COLUMN.filter((item) => item.filter !== 1);
      }
    },
    downloadImage(record) {
      this.downloadImageParent(record);
    },
    viewDetail(index) {
      this.viewDetailParent(index);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      // this.setSelectCameraList(selectedRows);
      this.$emit('onTable', selectedRows);
    },
    del(records) {
      if (this.fromTab === '我的截图') {
        // 我
        if (!this.hasPermission(109211111012)) {
          this.permissionWarning();
          return;
        }
      } else if (this.fromTab === '省级管理') {
        // 部
        if (!this.hasPermission(109211111113)) {
          this.permissionWarning();
          return;
        }
      }

      let ids = [
        {
          id: records.id,
          userId: records.gmtCreateUid,
          fileSize: records.fileSize,
        },
      ];
      let data = {
        instructionsDel: {
          module: '截图和录像',
          page: '截图管理',
          feature: '删除截图',
          description: `删除截图: ${records.cameraName || '暂无名称'}`,
        },
        ids,
      };
      this.$confirm({
        title: '提示',
        content: '你确定删除该截图吗?',
        onOk: () => {
          this.deleteScreenShout(data).then((res) => {
            if (res.code === 200) {
              this.$message.success(`删除${ids.length}条记录成功`);
              // this.searchHandle();
              this.$emit('sureSearch');
            }
          });
        },
        onCancel: () => {},
      });
    },
    searchHandle() {
      let data = {
        ...this.screenShotSearchParams,
      };
      this.setScreenShotSearchParams(data);
      this.getScreenShotInfo(data);
    },
  },
};
</script>

<style scoped></style>
