<template>
  <a-modal
    title="图像分析"
    :visible="visible"
    width="30%"
    :centered="true"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="flex items-center">
      <div
        class="flex-one m-l-sm m-r-sm pointer"
        v-for="item in dataList"
        :key="item.id"
        @click="selectHandle(item)"
      >
        <div class="flex-container relative" :class="{ current: item.check }">
          <div
            class="flex-inner text-white flex items-center justify-center f14"
          >
            <span v-html="item.text"></span>
          </div>
          <a-icon
            type="check-circle"
            v-show="item.check"
            class="absolute icon"
          />
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'ImageAnalysisDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    manualCamera: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      confirmLoading: false,
      dataList: [
        {
          id: 1,
          text: '交通<br/>状态',
          check: true,
        },
        {
          id: 2,
          text: '公路<br/>事件',
          check: false,
        },
        {
          id: 3,
          text: '视频<br/>质量',
          check: false,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    selectHandle(item) {
      /* this.dataList.forEach((item) => {
        item.check = false;
      }); */
      item.check = item.check ? false : true;
    },
    resetData() {
      this.dataList.forEach((item) => (item.check = false));
      this.dataList[0].check = true;
    },
    handleCancel() {
      this.resetData();
      this.$emit('closeImageAn');
    },
    handleOk() {
      let analysisContent = this.dataList
        .filter((item) => item.check)
        .map((item) => item.id)
        .join(',');
      let snapshots = this.manualCamera.map((item) => {
        return {
          cameraNum: item.cameraNum,
          snapshotTime: item.message,
          snapshotUrl: item.url,
        };
      });
      let params = {
        analysisContent,
        snapshots,
      };
      this.confirmLoading = true;
      this.$api.imageAn.postManual(params).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('手动图像分析成功');
          this.resetData();
          this.$emit('sureImageAn');
        } else {
          this.$message.error('手动图像分析出错！');
        }
        this.confirmLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.flex-container {
  border: 1px solid #064173;
  border-radius: 100%;
  padding: 14px;
  &.current {
    background: rgba(6, 65, 115, 0.5);
  }
  .flex-inner {
    background: #064173;
    border-radius: 100%;
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    span {
      margin-top: 100%;
    }
  }
  .icon {
    right: 0;
    bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
