<template>
  <div class="full-width full-height flex items-center justify-center">
    <img src="@/assets/pic-holder.png" width="50%" />
  </div>
</template>

<script>
export default {
  name: 'ImageHolder',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
