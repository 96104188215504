<template>
  <a-modal
    title="视频压缩"
    :visible="visible"
    width="26%"
    :centered="true"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="flex items-center m-b-sm">
      <span class="flex-s m-r-xs title">输出分辨率:</span>
      <a-select v-model="currentResolution" class="flex-one">
        <a-select-option
          :value="item.value"
          :key="item.id"
          v-for="item in resolutions"
        >
          {{ item.text }}
        </a-select-option>
      </a-select>
    </div>
    <div class="flex items-center m-b-sm">
      <span class="flex-s m-r-xs title">输出内容:</span>
      <a-radio-group v-model="exportContent">
        <a-radio :value="1">
          缩小优先
        </a-radio>
        <a-radio :value="2">
          清晰度优先
        </a-radio>
      </a-radio-group>
    </div>
    <div class="flex items-center m-b-sm">
      <span class="flex-s m-r-xs title">输出格式:</span>
      <a-radio-group v-model="exportFormat">
        <a-radio :value="1">
          保持源格式
        </a-radio>
        <a-radio :value="2">
          MP4
        </a-radio>
      </a-radio-group>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  name: 'VideoCompress',
  data() {
    return {
      confirmLoading: false,
      currentResolution: 4,
      exportContent: 1,
      exportFormat: 1,
      resolutions: [
        {
          id: 0,
          value: 0,
          text: '800*600',
        },
        {
          id: 1,
          value: 1,
          text: '320*240',
        },
        {
          id: 2,
          value: 2,
          text: '640*480',
        },
        {
          id: 3,
          value: 3,
          text: '720*480',
        },
        {
          id: 4,
          value: 4,
          text: '原始分辨率',
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleOk() {
      this.$emit('sure');
    },
  },
};
</script>

<style scoped>
.title {
  width: 80px;
  text-align: right;
}
</style>
